import { ResultElement } from "./common";

const DATA_PRESIDENT_2012: readonly ResultElement[] = [
  {
    state: "Colorado",
    actualDemocrat: 51.2,
    actualRepublican: 46.5,
    forecastDemocrat: 95.5,
    forecastRepublican: 4.5,
    correct: "Yes",
  },
  {
    state: "Florida",
    actualDemocrat: 49.9,
    actualRepublican: 49.2,
    forecastDemocrat: 17.6,
    forecastRepublican: 82.4,
    correct: "No",
  },
  {
    state: "Iowa",
    actualDemocrat: 52.1,
    actualRepublican: 46.5,
    forecastDemocrat: 98.7,
    forecastRepublican: 1.3,
    correct: "Yes",
  },
  {
    state: "Michigan",
    actualDemocrat: 53.3,
    actualRepublican: 45.8,
    forecastDemocrat: 99.1,
    forecastRepublican: 0.9,
    correct: "Yes",
  },
  {
    state: "Nevada",
    actualDemocrat: 52.4,
    actualRepublican: 45.6,
    forecastDemocrat: 98.1,
    forecastRepublican: 1.9,
    correct: "Yes",
  },
  {
    state: "New Hampshire",
    actualDemocrat: 52,
    actualRepublican: 46.7,
    forecastDemocrat: 98.2,
    forecastRepublican: 1.8,
    correct: "Yes",
  },
  {
    state: "North Carolina",
    actualDemocrat: 48.4,
    actualRepublican: 50.6,
    forecastDemocrat: 1.4,
    forecastRepublican: 98.6,
    correct: "Yes",
  },
  {
    state: "Ohio",
    actualDemocrat: 50.1,
    actualRepublican: 48.2,
    forecastDemocrat: 99.9,
    forecastRepublican: 0.1,
    correct: "Yes",
  },
  {
    state: "Pennsylvania",
    actualDemocrat: 51.9,
    actualRepublican: 46.8,
    forecastDemocrat: 98.8,
    forecastRepublican: 1.2,
    correct: "Yes",
  },
  {
    state: "Virginia",
    actualDemocrat: 50.5,
    actualRepublican: 48,
    forecastDemocrat: 72.6,
    forecastRepublican: 27.4,
    correct: "Yes",
  },
  {
    state: "Wisconsin",
    actualDemocrat: 52,
    actualRepublican: 46.9,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
];

export default DATA_PRESIDENT_2012;
