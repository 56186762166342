// css
import "./site/scss/main.scss";
import "nprogress/nprogress.css";
import "./lib/multiselect/bootstrap-multiselect.css";

// Vendor
import "bootstrap-sass/assets/javascripts/bootstrap.js";
import "./lib/multiselect/bootstrap-multiselect.js";
import "./lib/jquery/plugins/highcharts/highcharts.js";
import "./lib/jquery/plugins/highcharts/modules/map.js";
import "./lib/jquery/plugins/highcharts/map-data/us-small.js";

import "./services/progress";

// autoload components
import "./components/custom-forecasts";
import "./components/custom-scenarios";
import "./components/data-dumper";
import "./components/display-polls";
import "./components/election-multiview";
// import "./components/election-overview";
import "./components/election-selector";
// import "./components/ev-bar";
import "./components/histogram";
import "./components/location-overview";
import "./components/map";
import "./components/race-overview";
import "./components/sankey-graph";
import "./components/sliders-multiview";
import "./components/sliders";
import "./components/title";
import "./components/trends-graph";
import "./components/trends";
import "./components/select";
import "./components/calendar";
import "./components/slider";
