declare namespace Theme {
  export namespace Colors {
    export interface Parties {
      // same as bootstrap's .table-hover background
      neutral: string;

      // Political Parties
      // see `color.service.js`
      demWeak: string;
      dem: string;
      demAlt: string;
      demStrong: string;

      demindWeak: string;
      demind: string;
      demindAlt: string;
      demindStrong: string;

      indWeak: string;
      ind: string;
      indAlt: string;
      indStrong: string;

      repWeak: string;
      rep: string;
      repAlstring: string;
      repStrong: string;

      otherWeak: string;
      other: string;
      otherStrong: string;

      mixed: string;
    }
  }

  export interface Colors {
    parties: Colors.Parties;

    // American Flag
    // From: http://www.colourlovers.com/palette/3263/American_Flag
    // Used to generate the red-white-blue striped border image.
    red: string;
    white: string;
    blue: string;

    // Custom Colors
    gray: string;
    lightGray: string;
    lighterGray: string;
    lightererGray: string;
    lighterererGray: string;

    // UIUC
    uiucOrange: string;

    // NProgress Loading Bar overrides
    loadingbarColor: string;

    background: string;
  }

  export interface Geometry {
    spacing(multiplier: number): number;
    cornerRaidus: number;
  }
}

export interface Theme {
  colors: Theme.Colors;
  geometry: Theme.Geometry;
}

const theme: Theme = {
  colors: {
    parties: {
      // same as bootstrap's .table-hover background
      neutral: "#F5F5F5",

      // Political Parties
      // see `color.service.js`
      demWeak: "#bad9f7",
      dem: "#76b2ef",
      demAlt: "#0052A5",
      demStrong: "#318CE7",

      demindWeak: "#00CED1",
      demind: "#1E718C",
      demindAlt: "#1E718C",
      demindStrong: "#1E718C",

      indWeak: "#58B661",
      ind: "#1FA72C",
      indAlt: "#009321",
      indStrong: "#16741F",

      repWeak: "#fcc3c0",
      rep: "#f98680",
      repAlstring: "#E0162B",
      repStrong: "#F64A41",

      otherWeak: "#EEEEEE",
      other: "#CCCCCC",
      otherStrong: "#999999",

      mixed: "purple",
    },
    // American Flag
    // From: http://www.colourlovers.com/palette/3263/American_Flag
    // Used to generate the red-white-blue striped border image.
    red: "#E0162B",
    white: "#FFFFFF",
    blue: "#0052A5",

    // Custom Colors
    gray: "#222",
    lightGray: "#999",
    lighterGray: "#adadad",
    lightererGray: "#e6e6e6",
    lighterererGray: "#f8f8f8",

    // UIUC
    uiucOrange: "#E84A27",

    // NProgress Loading Bar overrides
    loadingbarColor: "0052A5",

    background: "white",
  },
  geometry: {
    cornerRaidus: 0,
    spacing(multiplier) {
      return multiplier * 4;
    },
  },
};

export default theme;
