import { ResultElement } from "./common";

const DATA_SENATE_2012: readonly ResultElement[] = [
  {
    state: "Arizona",
    actualDemocrat: 45.3,
    actualRepublican: 50.4,
    forecastDemocrat: 5.7,
    forecastRepublican: 94.3,
    correct: "Yes",
  },
  {
    state: "Connecticut",
    actualDemocrat: 55.1,
    actualRepublican: 43.2,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Florida",
    actualDemocrat: 55.1,
    actualRepublican: 42.3,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Indiana",
    actualDemocrat: 49.9,
    actualRepublican: 44.4,
    forecastDemocrat: 93.1,
    forecastRepublican: 6.9,
    correct: "Yes",
  },
  {
    state: "Massachusetts",
    actualDemocrat: 53.7,
    actualRepublican: 46.3,
    forecastDemocrat: 99,
    forecastRepublican: 1,
    correct: "Yes",
  },
  {
    state: "Missouri",
    actualDemocrat: 54.7,
    actualRepublican: 39.2,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Montana",
    actualDemocrat: 48.8,
    actualRepublican: 44.7,
    forecastDemocrat: 47.4,
    forecastRepublican: 52.6,
    correct: "No",
  },
  {
    state: "Nebraska",
    actualDemocrat: 41.8,
    actualRepublican: 58.2,
    forecastDemocrat: 0,
    forecastRepublican: 100,
    correct: "Yes",
  },
  {
    state: "Nevada",
    actualDemocrat: 44.7,
    actualRepublican: 45.9,
    forecastDemocrat: 1.1,
    forecastRepublican: 98.9,
    correct: "Yes",
  },
  {
    state: "North Dakota",
    actualDemocrat: 50.5,
    actualRepublican: 49.5,
    forecastDemocrat: 13.5,
    forecastRepublican: 86.5,
    correct: "No",
  },
  {
    state: "Ohio",
    actualDemocrat: 53.3,
    actualRepublican: 45.1,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Pennsylvania",
    actualDemocrat: 53.6,
    actualRepublican: 44.7,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Virginia",
    actualDemocrat: 52.4,
    actualRepublican: 47.6,
    forecastDemocrat: 98.6,
    forecastRepublican: 1.4,
    correct: "Yes",
  },
  {
    state: "Wisconsin",
    actualDemocrat: 51.5,
    actualRepublican: 45.9,
    forecastDemocrat: 96,
    forecastRepublican: 4,
    correct: "Yes",
  },
];

export default DATA_SENATE_2012;
