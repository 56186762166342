import { ResultWithTurnOutElement } from "./common";

const DATA_SENATE_2018: readonly ResultWithTurnOutElement[] = [
  {
    state: "Arizona",
    democrat: 49.7,
    republican: 48.0,
    strongDemocratTurnout: "(76.2%, 23.8%)",
    neutral: "(35.2%, 64.8%)",
    strongRepulbicanTurnout: "(7.1%, 92.9%)",
  },
  {
    state: "Florida",
    democrat: 49.9,
    republican: 50.1,
    strongDemocratTurnout: "(100%, 0%)",
    neutral: "(99.6%, 0.4%)",
    strongRepulbicanTurnout: "(89.4%, 10.6%)",
  },
  {
    state: "Indiana",
    democrat: 45.1,
    republican: 51.0,
    strongDemocratTurnout: "(99.4%, 0.6%)",
    neutral: "(70.9%, 20.1%)",
    strongRepulbicanTurnout: "(7.9%, 92.1%)",
  },
  {
    state: "Missouri",
    democrat: 45.5,
    republican: 51.5,
    strongDemocratTurnout: "(74.5%, 25.5%)",
    neutral: "(20.9%, 79.1%)",
    strongRepulbicanTurnout: "(1.1%, 98.9%)",
  },
  {
    state: "Montana",
    democrat: 50.1,
    republican: 47.0,
    strongDemocratTurnout: "(100%, 0%)",
    neutral: "(99.9%, 0.1%)",
    strongRepulbicanTurnout: "(99.8%, 0.2%)",
  },
  {
    state: "North Dakota",
    democrat: 44.6,
    republican: 55.4,
    strongDemocratTurnout: "(0%, 100%)",
    neutral: "(0%, 100%)",
    strongRepulbicanTurnout: "(0%, 100%)",
  },
  {
    state: "Nevada",
    democrat: 50.4,
    republican: 45.4,
    strongDemocratTurnout: "(86.0%, 14.0%)",
    neutral: "(41.9%, 58.1%)",
    strongRepulbicanTurnout: "(6.9%, 93.1%)",
  },
  {
    state: "Tennessee",
    democrat: 43.9,
    republican: 54.7,
    strongDemocratTurnout: "(0.1%, 99.9%)",
    neutral: "(0.1%, 99.9%)",
    strongRepulbicanTurnout: "(0%, 100%)",
  },
  {
    state: "Texas",
    democrat: 48.3,
    republican: 50.9,
    strongDemocratTurnout: "(0.2%, 99.8%)",
    neutral: "(0.1%, 99.9%)",
    strongRepulbicanTurnout: "(0%, 100%)",
  },
];

export default DATA_SENATE_2018;
