import "./all";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "emotion-theming";
import { css, SerializedStyles } from "@emotion/core";
import theme, { Theme } from "./theme";
import CollapsePanel from "./components/next/CollapsePanel";
import Table from "./components/next/Table";
import TableRow from "./components/next/TableRow";
import TableHead from "./components/next/TableHead";
import TableHeaderCell from "./components/next/TableHeaderCell";
import TableDataCell from "./components/next/TableDataCell";
import ResultTable from "./components/next/ResultTable";
import ResultTableRow from "./components/next/ResultTableRow";
import ResultTableWithTurnOut from "./components/next/ResultTableWithTurnOut";
import ResultTableWithTurnOutRow from "./components/next/ResultTableWithTurnOutRow";
import DATA_SENATE_2020 from "./data/senate2020";
import DATA_SENATE_2018 from "./data/senate2018";
import DATA_SENATE_2014 from "./data/senate2014";
import DATA_SENATE_2012 from "./data/senate2012";
import DATA_PRESIDENT_2020 from "./data/president2020";
import DATA_PRESIDENT_2012 from "./data/president2012";
import DATA_PRESIDENT_2008 from "./data/president2008";
import TableBody from "./components/next/TableBody";

function strongRep(theme: Theme): SerializedStyles {
  return css`
    color: ${theme.colors.parties.repStrong};
  `;
}

function strongDem(theme: Theme): SerializedStyles {
  return css`
    color: ${theme.colors.parties.demStrong};
  `;
}

function rep(theme: Theme): SerializedStyles {
  return css`
    color: ${theme.colors.parties.rep};
  `;
}

function dem(theme: Theme): SerializedStyles {
  return css`
    color: ${theme.colors.parties.dem};
  `;
}

function Result(): JSX.Element {
  return (
    <Fragment>
      <p>
        Election Analytics tracks and analyzes polling data to forecast who will
        win the United States Presidency and which party will secure control of
        the United States Senate. Without any political commentary or partisan
        opinion, Election Analytics delivers the facts in a simple, concise
        format. Election Analytics, a student run STEM (Science, Technology,
        Engineering, and Mathematics) learning laboratory, uses Bayesian
        statistics and operations research methodologies to make sense of the
        daily stream of polling data reported in the{" "}
        <a href="http://www.realclearpolitics.com/?state=nwa">national media</a>
        . Election Analytics provides a snapshot of the current state of the
        election, forecasting the outcome if the election was held today.
        Available since 2008, Election Analytics provides a full history of its
        performance.
      </p>
      <h2>2020 Election</h2>
      <CollapsePanel title="President 2020">
        <div className="panel-body">
            In the 2020 presidential race, Biden won 306 electoral votes and Trump won 232. The tables below show our forecasts for battleground states
            using three poll weights (10%, 30%, and 100%) and three turnout scenarios (Very Strong Democrat, Neutral, and Very Strong Republican). The 
            scenario that came the closest to the actual results was 10% poll weight with Very Strong Republican, predicting 315.6 and 222.4 electoral 
            votes for Biden and Trump, respectively.
        </div>
        {DATA_PRESIDENT_2020.map((element, index) => {
          const { pollWeight, rows } = element;

          return (
            <div key={index}>
              <h3 className="panel-body">{pollWeight}%</h3>
              <ResultTableWithTurnOut republican="Trump" democrat="Biden">
                {rows.map((row, index) => {
                  return <ResultTableWithTurnOutRow key={index} {...row} />;
                })}
              </ResultTableWithTurnOut>
            </div>
          );
        })}
      </CollapsePanel>

      <CollapsePanel title="Senate 2020">
        <div className="panel-body">
            There were 35 Senate races in 2020. We present forecasts for battleground states using three poll weights (10%, 30%, and 100%) with
            three turnout scenarios (Very Strong Democrat, Neutral, and Very Strong Republican).
        </div>
        {DATA_SENATE_2020.map((element, index) => {
          const { pollWeight, rows } = element;

          return (
            <div key={index}>
              <h3 className="panel-body">{pollWeight}%</h3>
              <ResultTableWithTurnOut>
                {rows.map((row, index) => {
                  return <ResultTableWithTurnOutRow key={index} {...row} />;
                })}
              </ResultTableWithTurnOut>
            </div>
          );
        })}
      </CollapsePanel>

      <h2>Previous Years</h2>
      <div aria-multiselectable="true">
        <CollapsePanel title={<span className="panel-title">Senate 2018</span>}>
          <div className="panel-body">
            There were 35 Senate races in 2018. We present forecasts for battleground states with three turnout scenarios: Very Strong Democrat, Neutral, and Very Strong Republican.
          </div>

          <ResultTableWithTurnOut>
            {DATA_SENATE_2018.map((item, index) => {
              return (
                <ResultTableWithTurnOutRow
                  key={index}
                  {...item}
                ></ResultTableWithTurnOutRow>
              );
            })}
          </ResultTableWithTurnOut>
          {/* <div className="panel-footer">
            <sup>1</sup> footer
          </div> */}
        </CollapsePanel>

        <CollapsePanel
          title={<span className="panel-title">President 2016</span>}
        >
          <div>
            <Table>
              <TableHead>
                <TableHeaderCell>
                  Undecided Voter Turnout Scenarios
                </TableHeaderCell>
                <TableHeaderCell>
                  Trump vs Clinton <br />
                  vs Johnson vs Stein
                </TableHeaderCell>
                <TableHeaderCell>
                  Trump vs Clinton <br />
                  vs Johnson
                </TableHeaderCell>
                <TableHeaderCell>Trump vs Clinton</TableHeaderCell>
              </TableHead>
              <TableBody>
                <TableRow css={strongRep}>
                  <TableDataCell variant="text">
                    Very Strong Republican
                  </TableDataCell>
                  <TableDataCell>(15.65%, 259.30)</TableDataCell>
                  <TableDataCell>(37.03%, 266.61)</TableDataCell>
                  <TableDataCell>(77.66%, 274.63)</TableDataCell>
                </TableRow>
                <TableRow css={strongRep}>
                  <TableDataCell variant="text">
                    Strong Republican
                  </TableDataCell>
                  <TableDataCell>(3.25%, 250.49)</TableDataCell>
                  <TableDataCell>(7.63%, 257.01)</TableDataCell>
                  <TableDataCell>(20.03%, 261.71)</TableDataCell>
                </TableRow>
                <TableRow css={rep}>
                  <TableDataCell variant="text">Mild Republican</TableDataCell>
                  <TableDataCell>(0.63%, 245.71)</TableDataCell>
                  <TableDataCell>(2.38%, 251.43)</TableDataCell>
                  <TableDataCell>(4.90%, 254.41)</TableDataCell>
                </TableRow>
                <TableRow>
                  <TableDataCell variant="text">Neutral</TableDataCell>
                  <TableDataCell>(0.36%, 242.00)</TableDataCell>
                  <TableDataCell>(0.55%, 245.05)</TableDataCell>
                  <TableDataCell>(0.68%, 246.50)</TableDataCell>
                </TableRow>
                <TableRow css={dem}>
                  <TableDataCell variant="text">Mild Democrat</TableDataCell>
                  <TableDataCell>(0.10%, 238.22)</TableDataCell>
                  <TableDataCell>(0.06%, 238.30)</TableDataCell>
                  <TableDataCell>(0.05%, 238.40)</TableDataCell>
                </TableRow>
                <TableRow css={strongDem}>
                  <TableDataCell variant="text">Strong Democrat</TableDataCell>
                  <TableDataCell>(0.02%, 233.38)</TableDataCell>
                  <TableDataCell>(0.01%, 230.98)</TableDataCell>
                  <TableDataCell>(0.00%, 227.71)</TableDataCell>
                </TableRow>
                <TableRow css={strongDem}>
                  <TableDataCell variant="text">
                    Very Strong Democrat
                  </TableDataCell>
                  <TableDataCell>(0.00%, 222.78)</TableDataCell>
                  <TableDataCell>(0.00%, 217.42)</TableDataCell>
                  <TableDataCell>(0.00%, 208.44)</TableDataCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="panel-body">
            <p>
              Table 1A: Chance of Donald Trump Winning & Expected Number of
              Electoral College Votes
            </p>
          </div>
          <p className="panel-body">
            In only one of the 21 scenarios did Donald Trump win the election
            (Undecided Voter Turnout Scenario: Very Strong Republican, Two-Way
            race: Trump vs Clinton). In all three Undecided Voter Turnout
            Scenarios, the Very Strong Republican scenarios all give Trump’s
            chance of winning to be greater than 15%. The lower amount of
            support for the third party candidates also contributed to the
            election acting like a Two-Way race. This suggests that the
            undecided voters broke sharply to Donald Trump, which enabled his
            victory.
          </p>

          <p className="panel-body">
            Can anything be learned from the 2012 election between Barack Obama
            and Mitt Romney? Given no significant third party candidates, there
            were only seven scenarios, all reported in the Table 1B. Even in the
            most favorable environment for Romney, in terms of undecided voters,
            Obama still has a 98.69% chance of winning the election, which he
            did easily. This suggests that one must view all scenarios as a
            collection of options, rather than any one scenario as being the
            definitive outcome.
          </p>

          <div>
            <Table>
              <TableHead>
                <TableHeaderCell>
                  Undecided Voter Turnout Scenarios
                </TableHeaderCell>
                <TableHeaderCell>Obama vs Romney</TableHeaderCell>
              </TableHead>
              <TableBody>
                <TableRow css={strongRep}>
                  <TableDataCell variant="text">
                    Very Strong Republican
                  </TableDataCell>
                  <TableDataCell>(98.69%, 296.16)</TableDataCell>
                </TableRow>
                <TableRow css={strongRep}>
                  <TableDataCell variant="text">
                    Strong Republican
                  </TableDataCell>
                  <TableDataCell>(99.77%, 300.44)</TableDataCell>
                </TableRow>
                <TableRow css={rep}>
                  <TableDataCell variant="text">Mild Republican</TableDataCell>
                  <TableDataCell>(99.92%, 303.09)</TableDataCell>
                </TableRow>
                <TableRow>
                  <TableDataCell variant="text">Neutral</TableDataCell>
                  <TableDataCell>(99.97%, 305.97)</TableDataCell>
                </TableRow>
                <TableRow css={dem}>
                  <TableDataCell variant="text">Mild Democrat</TableDataCell>
                  <TableDataCell>(99.99%, 309.15)</TableDataCell>
                </TableRow>
                <TableRow css={strongDem}>
                  <TableDataCell variant="text">Strong Democrat</TableDataCell>
                  <TableDataCell>(100.00%, 312.55)</TableDataCell>
                </TableRow>
                <TableRow css={strongDem}>
                  <TableDataCell variant="text">
                    Very Strong Democrat
                  </TableDataCell>
                  <TableDataCell>(100.00%, 319.35)</TableDataCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className="panel-body">
            <p>
              Table 1B: Chance of Barack Obama Winning & Expected Number of
              Electoral College Votes
            </p>
          </div>
        </CollapsePanel>

        <CollapsePanel title={<span className="panel-title">Senate 2016</span>}>
          <p className="panel-body">
            On 8 November 2016, the Republican party retained control of the
            Senate, with a 52-48 majority. Election Analytics provided seven
            scenarios, based on various undecided voter turnout (Very Strong
            Republican, Strong Republican, Mild Republican, Neutral, Mild
            Democrat, Strong Democrat, and Very Strong Democrat). The neutral
            undecided voter turnout is what was showcased on the{" "}
            <a href="index.php">main page</a>, which gave the Republicans a
            60.85% chance of retaining control of the Senate.
          </p>

          <p className="panel-body">
            Table 2 provides the chance of the Republicans retaining control of
            the Senate and the expected number Senate seats they would win
            across all these seven scenarios. Blue indicates that the Democrats
            had the most likely scenario for gaining control of the Senate, red
            indicates that the Republicans had the most likely scenario for
            retaining control of the Senate, and purple indicates a 50-50 tie
            was the most likely scenario.
          </p>

          <Table>
            <TableHead>
              <TableHeaderCell>
                Undecided Voter Turnout Scenarios
              </TableHeaderCell>
              <TableHeaderCell>Republicans</TableHeaderCell>
              <TableHeaderCell>Dems & Inds</TableHeaderCell>
              <TableHeaderCell>Chance of Tie</TableHeaderCell>
            </TableHead>
            <TableBody>
              <TableRow css={strongRep}>
                <TableDataCell variant="text">
                  Very Strong Republican
                </TableDataCell>
                <TableDataCell>(98.68%, 52.04)</TableDataCell>
                <TableDataCell>(0.02% 47.96)</TableDataCell>
                <TableDataCell>1.30%</TableDataCell>
              </TableRow>
              <TableRow css={strongRep}>
                <TableDataCell variant="text">Strong Republican</TableDataCell>
                <TableDataCell>(89.56%, 51.41)</TableDataCell>
                <TableDataCell>(0.77%, 48.59)</TableDataCell>
                <TableDataCell>9.66%</TableDataCell>
              </TableRow>
              <TableRow css={rep}>
                <TableDataCell variant="text">Mild Republican</TableDataCell>
                <TableDataCell>(77.97%, 51.08)</TableDataCell>
                <TableDataCell>(2.93%, 48.92)</TableDataCell>
                <TableDataCell>19.10%</TableDataCell>
              </TableRow>
              <TableRow>
                <TableDataCell variant="text">Neutral</TableDataCell>
                <TableDataCell>(60.85%, 50.69)</TableDataCell>
                <TableDataCell>(8.69%, 49.31)</TableDataCell>
                <TableDataCell>30.45%</TableDataCell>
              </TableRow>
              <TableRow css={dem}>
                <TableDataCell variant="text">Mild Democrat</TableDataCell>
                <TableDataCell>(40.81%, 50.25)</TableDataCell>
                <TableDataCell>(20.41%, 49.75)</TableDataCell>
                <TableDataCell>38.79%</TableDataCell>
              </TableRow>
              <TableRow css={strongDem}>
                <TableDataCell variant="text">Strong Democrat</TableDataCell>
                <TableDataCell>(22.64%, 49.77)</TableDataCell>
                <TableDataCell>(38.40%, 50.23)</TableDataCell>
                <TableDataCell>38.96%</TableDataCell>
              </TableRow>
              <TableRow css={strongDem}>
                <TableDataCell variant="text">
                  Very Strong Democrat
                </TableDataCell>
                <TableDataCell>(3.51%, 48.80)</TableDataCell>
                <TableDataCell>(77.67%, 51.20)</TableDataCell>
                <TableDataCell>18.82%</TableDataCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="panel-body">
            <p>
              Table 2: Chance of the Republicans Retaining Control of the Senate
              & the Expected Number of Senate Seats that They Would Have
            </p>
          </div>

          <p className="panel-body">
            In four of the seven scenarios, the Republications had a higher than
            50% chance of retaining control of the Senate. This suggests that
            the undecided voters were generally more favorable to the Republican
            candidates than their Democrat opposition.
          </p>
        </CollapsePanel>

        <CollapsePanel title={<span className="panel-title">Senate 2014</span>}>
          <div className="panel-body">
            There were 36 Senate races in 2014. We present forecasts for battleground states under the Neutral turnout scenario.
            {/* Our model correctly forecast 35 of the 36 races, missing only North Carolina. The election-day
             forecast incorrectly forecasted that the race in Georgia would be
             decided by a runoff, but it did forecast the overall winner
             correctly. */}
          </div>

          <ResultTable>
            {DATA_SENATE_2014.map((row, index) => {
              return <ResultTableRow key={index} {...row} />;
            })}
          </ResultTable>

          <div className="panel-footer">
            {/* <sup>1</sup> The model incorrectly forecast a runoff in Georgia.
            However, it identified the Republican as the likely winner of the
            runoff race.
            <br /> */}
            <sup>1</sup> The Democrat columns for Kansas list the results for
            the independent candidate, Greg Orman.
            <br />
            {/* <sup>3</sup> On election day, the model correctly forecast a runoff
            in Louisiana. The percentages and chances reported here are for the
            runoff race on December 6th.
            <br /> */}
            <sup>2</sup> Brier Score: 0.0325, Entropy Score: 0.1200.
          </div>
        </CollapsePanel>

        <CollapsePanel
          title={<span className="panel-title">President 2012</span>}
        >
          <div className="panel-body">
            In the Neutral turnout scenario, the expected
            number of Electoral College votes for President Obama was 304.0,
            while 332 were actually achieved. This discrepancy corresponds to an
            error of 2.11 standard deviations (based on a standard deviation of
            13.30). The Strong Democratic turnout scenario was the closest to
            the actual results, forecasting 309.3 Electoral College votes for
            President Obama. The 11 battleground states are analyzed in the
            following table (under the neutral turnout scenario):
          </div>

          <ResultTable democrat="Obama" republican="Romney">
            {DATA_PRESIDENT_2012.map((row, index) => {
              return <ResultTableRow key={index} {...row} />;
            })}
          </ResultTable>

          <div className="panel-footer">
            {/* <sup>1</sup> All of these states, except Florida, were correctly
            forecast.
            <br /> */}
            <sup>1</sup> Originally posted at:{" "}
            <a
              href="http://election12.cs.illinois.edu/election12"
              target="_blank"
            >
              election12.cs.illinois.edu/election12
            </a>
            .<br />
            <sup>2</sup> Brier Score: 0.0149, Entropy Score: 0.0445.
          </div>
        </CollapsePanel>

        <CollapsePanel title={<span className="panel-title">Senate 2012</span>}>
          <div className="panel-body">
            In the Neutral turnout scenario, the expected number of Senate seats for
            the Democrats and Independents was 53.6, while 55 were actually
            achieved. This discrepancy corresponds to an error of 1.87 standard
            deviations (based on a standard deviation of 0.75). The Strong
            Democratic turnout scenario was the closest to the actual results,
            forecasting 53.9 Senate seats for the Democrats and Independents.
            The 14 battleground states are analyzed in the
            following table (under the neutral turnout scenario):
          </div>

          <ResultTable>
            {DATA_SENATE_2012.map((row, index) => {
              return <ResultTableRow key={index} {...row} />;
            })}
          </ResultTable>

          <div className="panel-footer">
            {/* <sup>1</sup> All of these states, except for Montana and North
            Dakota, were correctly forecast.
            <br /> */}
            <sup>1</sup> Originally posted at:{" "}
            <a
              href="http://election12.cs.illinois.edu/senate12"
              target="_blank"
            >
              election12.cs.illinois.edu/senate12
            </a>
            .<br />
            <sup>2</sup> Brier Score: 0.0314, Entropy Score: 0.0899.
          </div>
        </CollapsePanel>

        <CollapsePanel
          title={<span className="panel-title">President 2008</span>}
        >
          <div className="panel-body">
            In the Neutral scenario, the expected number of Electoral
            College votes for President-Elect Obama was 356.3, while 365 were
            actually captured. This discrepancy corresponds to an error of 0.77
            standard deviations (based on a standard deviation of 11.24). The
            Strong Democrat turnout scenario was the closest to the actual
            results, forecasting 362 Electoral College votes.
            The 11 battleground states are analyzed in the
            following table:
          </div>

          <ResultTable republican="John McCain" democrat="Barack Obama">
            {DATA_PRESIDENT_2008.map((row, index) => {
              return <ResultTableRow key={index} {...row} />;
            })}
          </ResultTable>

          <div className="panel-footer">
            {/* <sup>1</sup> All of these states, except Indiana, were correctly
            forecast. Of the five states with a 85% or lower chance of the
            leading candidate winning, four were correctly forecast.
            <br /> */}
            <sup>1</sup> Originally posted at:{" "}
            <a href="http://election08.cs.illinois.edu" target="_blank">
              election08.cs.illinois.edu
            </a>
            .<br />
            <sup>2</sup> Brier Score: 0.0161, Entropy Score: 0.0525.
          </div>
        </CollapsePanel>
      </div>
    </Fragment>
  );
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Result />
  </ThemeProvider>,
  document.getElementById("container")
);
