import { ResultElement } from "./common";

const DATA_PRESIDENT_2008: readonly ResultElement[] = [
  {
    state: "Colorado",
    actualDemocrat: 53,
    actualRepublican: 46,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Florida",
    actualDemocrat: 51,
    actualRepublican: 49,
    forecastDemocrat: 97,
    forecastRepublican: 3,
    correct: "Yes",
  },
  {
    state: "Indiana",
    actualDemocrat: 50,
    actualRepublican: 49,
    forecastDemocrat: 27,
    forecastRepublican: 73,
    correct: "No",
  },
  {
    state: "Missouri",
    actualDemocrat: 49,
    actualRepublican: 50,
    forecastDemocrat: 30,
    forecastRepublican: 70,
    correct: "Yes",
  },
  {
    state: "Montana",
    actualDemocrat: 47,
    actualRepublican: 50,
    forecastDemocrat: 26,
    forecastRepublican: 74,
    correct: "Yes",
  },
  {
    state: "Nevada",
    actualDemocrat: 55,
    actualRepublican: 43,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "New Mexico",
    actualDemocrat: 57,
    actualRepublican: 42,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "North Carolina",
    actualDemocrat: 50,
    actualRepublican: 49,
    forecastDemocrat: 71,
    forecastRepublican: 29,
    correct: "Yes",
  },
  {
    state: "North Dakota",
    actualDemocrat: 45,
    actualRepublican: 53,
    forecastDemocrat: 25,
    forecastRepublican: 75,
    correct: "Yes",
  },
  {
    state: "Ohio",
    actualDemocrat: 51,
    actualRepublican: 47,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
  {
    state: "Virginia",
    actualDemocrat: 52,
    actualRepublican: 47,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
];

export default DATA_PRESIDENT_2008;
