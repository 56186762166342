import { Result2020Table } from "./common";

const DATA_PRESIDENT_2020: Result2020Table[] = [
  {
    pollWeight: 10,
    rows: [
      {
        state: "Arizona",
        democrat: 49.4,
        republican: 49.1,
        strongDemocratTurnout: "(74.0%, 26.0%)",
        neutral: "(62.5%, 37.5%)",
        strongRepulbicanTurnout: "(49.8%, 50.2%)",
      },
      {
        state: "Florida",
        democrat: 47.9,
        republican: 51.2,
        strongDemocratTurnout: "(90.6%, 9.4%)",
        neutral: "(79.9%, 20.1%)",
        strongRepulbicanTurnout: "(64.0%, 36.0%)",
      },
      {
        state: "Georgia",
        democrat: 49.5,
        republican: 49.2,
        strongDemocratTurnout: "(41.7%, 58.3%)",
        neutral: "(32.6%, 67.4%)",
        strongRepulbicanTurnout: "(24.4%, 75.6%)",
      },
      {
        state: "Iowa",
        democrat: 45.0,
        republican: 53.2,
        strongDemocratTurnout: "(40.9%, 59.1%)",
        neutral: "(29.3%, 70.7%)",
        strongRepulbicanTurnout: "(19.5%, 80.5%)",
      },
      {
        state: "Michigan",
        democrat: 50.6,
        republican: 47.9,
        strongDemocratTurnout: "(99.5%, 0.5%)",
        neutral: "(98.5%, 1.5%)",
        strongRepulbicanTurnout: "(96.0%, 4.0%)",
      },
      {
        state: "Nevada",
        democrat: 50.1,
        republican: 47.7,
        strongDemocratTurnout: "(70.0%, 30.0%)",
        neutral: "(64.2%, 35.8%)",
        strongRepulbicanTurnout: "(58.0%, 42.0%)",
      },
      {
        state: "North Carolina",
        democrat: 48.7,
        republican: 50.0,
        strongDemocratTurnout: "(75.0%, 25.0%)",
        neutral: "(63.7%, 36.3%)",
        strongRepulbicanTurnout: "(51.0%, 49.0%)",
      },
      {
        state: "Ohio",
        democrat: 45.2,
        republican: 53.4,
        strongDemocratTurnout: "(57.0%, 43.0%)",
        neutral: "(42.3%, 57.7%)",
        strongRepulbicanTurnout: "(28.5%, 71.5%)",
      },
      {
        state: "Pennsylvania",
        democrat: 49.9,
        republican: 48.9,
        strongDemocratTurnout: "(97.7%, 2.3%)",
        neutral: "(94.8%, 5.2%)",
        strongRepulbicanTurnout: "(89.4%, 10.6%)",
      },
      {
        state: "Wisconsin",
        democrat: 49.6,
        republican: 48.9,
        strongDemocratTurnout: "(99.3%, 0.7%)",
        neutral: "(98.5%, 1.5%)",
        strongRepulbicanTurnout: "(97.0%, 3.0%)",
      },
    ],
  },
  {
    pollWeight: 30,
    rows: [
      {
        state: "Arizona",
        democrat: 49.4,
        republican: 49.1,
        strongDemocratTurnout: "(89.7%, 10.3%)",
        neutral: "(76.1%, 23.9%)",
        strongRepulbicanTurnout: "(56.0%, 44.0%)",
      },
      {
        state: "Florida",
        democrat: 47.9,
        republican: 51.2,
        strongDemocratTurnout: "(99.0%, 1.0%)",
        neutral: "(93.3%, 6.7%)",
        strongRepulbicanTurnout: "(75.0%, 25.0%)",
      },
      {
        state: "Georgia",
        democrat: 49.5,
        republican: 49.2,
        strongDemocratTurnout: "(43.1%, 56.9%)",
        neutral: "(27.8%, 72.2%)",
        strongRepulbicanTurnout: "(15.9%, 84.1%)",
      },
      {
        state: "Iowa",
        democrat: 45.0,
        republican: 53.2,
        strongDemocratTurnout: "(50.5%, 49.5%)",
        neutral: "(29.7%, 70.3%)",
        strongRepulbicanTurnout: "(14.1%, 85.9%)",
      },
      {
        state: "Michigan",
        democrat: 50.6,
        republican: 47.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(99.9%, 0.1%)",
      },
      {
        state: "Nevada",
        democrat: 50.1,
        republican: 47.7,
        strongDemocratTurnout: "(78.8%, 21.2%)",
        neutral: "(70.4%, 29.6%)",
        strongRepulbicanTurnout: "(60.6%, 39.4%)",
      },
      {
        state: "North Carolina",
        democrat: 48.7,
        republican: 50.0,
        strongDemocratTurnout: "(90.1%, 9.9%)",
        neutral: "(76.7%, 23.3%)",
        strongRepulbicanTurnout: "(56.9%, 43.1%)",
      },
      {
        state: "Ohio",
        democrat: 45.2,
        republican: 53.4,
        strongDemocratTurnout: "(74.8%, 25.2%)",
        neutral: "(50.8%, 49.2%)",
        strongRepulbicanTurnout: "(26.5%, 73.5%)",
      },
      {
        state: "Pennsylvania",
        democrat: 49.9,
        republican: 48.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(99.8%, 0.2%)",
        strongRepulbicanTurnout: "(98.8%, 1.2%)",
      },
      {
        state: "Wisconsin",
        democrat: 49.6,
        republican: 48.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
    ],
  },
  {
    pollWeight: 100,
    rows: [
      {
        state: "Arizona",
        democrat: 49.4,
        republican: 49.1,
        strongDemocratTurnout: "(99.2%, 0.8%)",
        neutral: "(91.9%, 8.1%)",
        strongRepulbicanTurnout: "(64.9%, 35.1%)",
      },
      {
        state: "Florida",
        democrat: 47.9,
        republican: 51.2,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(99.7%, 0.3%)",
        strongRepulbicanTurnout: "(89.8%, 10.2%)",
      },
      {
        state: "Georgia",
        democrat: 49.5,
        republican: 49.2,
        strongDemocratTurnout: "(42.4%, 57.6%)",
        neutral: "(17.3%, 82.7%)",
        strongRepulbicanTurnout: "(4.6%, 95.4%)",
      },
      {
        state: "Iowa",
        democrat: 45.0,
        republican: 53.2,
        strongDemocratTurnout: "(61.9%, 38.1%)",
        neutral: "(24.4%, 75.6%)",
        strongRepulbicanTurnout: "(4.6%, 95.4%)",
      },
      {
        state: "Michigan",
        democrat: 50.6,
        republican: 47.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
      {
        state: "Nevada",
        democrat: 50.1,
        republican: 47.7,
        strongDemocratTurnout: "(91.7%, 8.3%)",
        neutral: "(81.9%, 18.1%)",
        strongRepulbicanTurnout: "(66.8%, 33.2%)",
      },
      {
        state: "North Carolina",
        democrat: 48.7,
        republican: 50.0,
        strongDemocratTurnout: "(99.2%, 0.8%)",
        neutral: "(92.2%, 7.8%)",
        strongRepulbicanTurnout: "(65.8%, 34.2%)",
      },
      {
        state: "Ohio",
        democrat: 45.2,
        republican: 53.4,
        strongDemocratTurnout: "(92.9%, 7.1%)",
        neutral: "(61.0%, 39.0%)",
        strongRepulbicanTurnout: "(18.2%, 81.8%)",
      },
      {
        state: "Pennsylvania",
        democrat: 49.9,
        republican: 48.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
      {
        state: "Wisconsin",
        democrat: 49.6,
        republican: 48.9,
        strongDemocratTurnout: "(100.0%, 0.0%)",
        neutral: "(100.0%, 0.0%)",
        strongRepulbicanTurnout: "(100.0%, 0.0%)",
      },
    ],
  },
];

export default DATA_PRESIDENT_2020;
