import { ResultElement } from "./common";

const DATA_SENATE_2014: readonly ResultElement[] = [
  {
    state: "Alaska",
    actualDemocrat: 45.3,
    actualRepublican: 48.9,
    forecastDemocrat: 16.2,
    forecastRepublican: 83.8,
    correct: "Yes",
  },
  {
    state: "Arkansas",
    actualDemocrat: 39.5,
    actualRepublican: 56.5,
    forecastDemocrat: 0,
    forecastRepublican: 100,
    correct: "Yes",
  },
  {
    state: "Colorado",
    actualDemocrat: 45.2,
    actualRepublican: 49.3,
    forecastDemocrat: 0.9,
    forecastRepublican: 99.1,
    correct: "Yes",
  },
  {
    state: "Georgia",
    actualDemocrat: 45.1,
    actualRepublican: 53,
    forecastDemocrat: 1.6,
    forecastRepublican: 98.4,
    correct: "Partial",
  },
  {
    state: "Iowa",
    actualDemocrat: 43.7,
    actualRepublican: 52.2,
    forecastDemocrat: 12.1,
    forecastRepublican: 87.9,
    correct: "Yes",
  },
  {
    state: (
      <span>
        Kansas <sup>1</sup>
      </span>
    ),
    actualDemocrat: 42.5,
    actualRepublican: 48.1,
    forecastDemocrat: 37.4,
    forecastRepublican: 62.6,
    correct: "Yes",
  },
  {
    state: "Kentucky",
    actualDemocrat: 40.7,
    actualRepublican: 56.2,
    forecastDemocrat: 0,
    forecastRepublican: 100,
    correct: "Yes",
  },
  {
    state: "Louisiana",
    actualDemocrat: 44.1,
    actualRepublican: 55.9,
    forecastDemocrat: 0,
    forecastRepublican: 100,
    correct: "Yes",
  },
  {
    state: "New Hampshire",
    actualDemocrat: 51.6,
    actualRepublican: 48.4,
    forecastDemocrat: 92.9,
    forecastRepublican: 7.1,
    correct: "Yes",
  },
  {
    state: "North Carolina",
    actualDemocrat: 47.3,
    actualRepublican: 49,
    forecastDemocrat: 95.9,
    forecastRepublican: 4.1,
    correct: "No",
  },
  {
    state: "Virginia",
    actualDemocrat: 49.2,
    actualRepublican: 48.4,
    forecastDemocrat: 100,
    forecastRepublican: 0,
    correct: "Yes",
  },
];

export default DATA_SENATE_2014
